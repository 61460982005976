import React, { useState } from "react";
import { PageLayout, ArticleList } from "@components";
import { graphql } from "gatsby";
import BackgroundFadeContainer from "@components/BackgroundFadeContainer/BackgroundFadeContainer";
import { BackgroundShapes } from "@components/BackgroundShapes/BackgroundShapes";
import { BlueSquares1, BlueSquares2 } from "@assets/Background";
import * as styles from "./caseStudies.module.scss";
import ArticleCard from "@components/ResourceCommomLayout/ArticleCard";
import LearnMoreSection from "@components/LearnMoreSection/LearnMoreSection";
import { useWindowSize } from "@helpers/Hooks";
import { CmsNode } from "@components/CMS/CmsTypes";
import CaseHero from "./components/CaseHero";
import Margins from "@components/Margins/Margins";
import TrustedByV2 from "@components/TrustedByV2/TrustedByV2";
import { JoinInstitutionsV2 } from "../landingpagev2/_components/_JoinInstitutionsV2/_JoinInstitutionsV2";
import TestimonialCard from "../../components/TestimonialCard/TestimonialCard";
import CaseTestimonials from "./components/CaseTestimonials";

type CaseStudyProps = {
	data: {
		caseStudies: {
			nodes: CmsNode[];
		};
	};
};

export default function ResourcesCaseStudiesPage({ data }: CaseStudyProps) {
	let { width, isMobile } = useWindowSize();
	const [displayNum, setDisplayNum] = useState(5);


	const { caseStudies } = data;
	const firstCaseStudy = caseStudies.nodes[0];
	const articleList = caseStudies.nodes.slice(1);

	return (
		<PageLayout
			seoOptions={{ type: "PREDEFINED", pageName: "case-studies" }}
		>
			<Margins className={styles.margin}>
				<CaseHero />
				<div className={styles.faqbg}>
					<div className={styles.roibg}>
						<ArticleList
							data={caseStudies?.nodes}
							listTitle="Recent case studies"
							type="studies"
							displayNum={displayNum}
							setDisplayNum={setDisplayNum}
						/>
					</div>
				</div>
				<div
					style={{
						width: "100%",
						maxWidth: "1400px",
						margin: "auto",
					}}
				>
					<TrustedByV2
						trustedByContainerClass={styles.trustedByContainerClass}
						logosContainerClass={styles.logosContainerClass}
						logoTabletClass={styles.logoTabletClass}
						logoClass={styles.logoClass}
					/>
				</div>
				<div className={styles.integrated_bg}>
					<CaseTestimonials />
					<JoinInstitutionsV2
						outerContainerClassName={styles.outerContainerClassName}
					/>
				</div>
			</Margins>
		</PageLayout>
	);
}

export const pageQuery = graphql`
	{
		caseStudies: allNodeCaseStudy(sort: { order: DESC, fields: created }) {
			nodes {
				title
				path {
					alias
				}
				body {
					summary
				}
				created(formatString: "MMMM DD, YYYY")
				relationships {
					field_feature_image {
						relationships {
							field_media_image {
								localFile {
									childImageSharp {
										gatsbyImageData
									}
								}
							}
						}
						field_media_image {
							alt
						}
					}
				}
			}
		}
	}
`;
