import React from "react";
import * as styles from "./CaseHero.module.scss";
import { Typography } from "@components";
import { StaticImage } from "gatsby-plugin-image";
import TypographyV2 from "@components/TypographyV2/TypographyV2";
import { Variant } from "../../../components/TypographyV2/TypographyV2";

const CaseHero = () => {
	return (
		<div className={styles.container}>
			<main className={styles.innercontainer}>
				<div className={styles.header}>
					<TypographyV2
						variant="HEADING_1"
						color="brand-500"
						className={styles.title}
					>
						Case studies
					</TypographyV2>
					<TypographyV2
						color="text-secondary"
						variant="BODY_TEXT_EXTRA_LARGE"
						className={styles.subtitle}
					>
						Discover how thousands of Scientists are using Genemod
						to drive their efficiency in lab management.
					</TypographyV2>
				</div>
				<div className={styles.image}>
					<StaticImage
						src="../assets/cashero.png"
						alt="case-studies-hero"
						className={styles.hero_image}
					/>
				</div>
				<div className={styles.tab_image}>
					<StaticImage
						src="../assets/herotablet.png"
						alt="case-studies-hero"
						className={styles.hero_image}
					/>
				</div>
			</main>
		</div>
	);
};

export default CaseHero;
